import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pills from "../components/pills"
import { rhythm, scale } from "../utils/typography"

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark
        const siteTitle = this.props.data.site.siteMetadata.title
				const { previous, next } = this.props.pageContext

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                    keywords={post.frontmatter.keywords}
                />
                <div style={{ marginBottom: rhythm(1) }}>
                    <h1>{post.frontmatter.title}</h1>
                    <p
                        style={{
                            ...scale(-1 / 5),
                            display: `block`,
                            marginTop: rhythm(-1),
                            marginBottom: 0,
                        }}
                    >
                        {post.frontmatter.date}
                    </p>
                    {post.frontmatter.categories && (
                        <Pills items={post.frontmatter.categories} />
                    )}
                </div>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                <hr
                    style={{
                        marginBottom: rhythm(1),
                    }}
                />
                <Bio />

                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                categories
                title
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
    }
`
